@import "../Colors";

.settings {
  //margin-top: 48px;
  margin: 12px auto;
  width: 416px;

  .card-exp {
    display: flex;
    > .dropdown-input {
      flex: 1;
      &:not(:last-of-type) {
        margin-right: 12px;
      }
    }
  }

  button.btn {
    margin-bottom: 12px;
    width: 50%;
  }

  .cal-weeks {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    > * {
      width: 48px;
      font-size: 20px;
      text-align: center;
      > div {
        //height: (416px - 16px) / 7 - 8px;
        padding-top: 100%;
        &.red {
          background-color: $danger;
        }
        &.green {
          background-color: $success;
        }
      }
      margin: 4px;
    }
  }

  .cal-body {
    margin-bottom: 12px;
  }

  h3 {
    font-size: 22px;
    margin-top: 16px;
    font-weight: normal;
  }
  > div > h2 {
    margin-top: 16px;
  }
}

.s-card {
  margin: 12px auto;
  padding: 12px;
  background-color: white;
  box-shadow: $light-shadow;
}