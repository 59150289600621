@import "../Colors";

.v-circle  {
  padding-right: 20px;
  width: 24px;
  &::before {
    content: '';
    height: 30px;
    top: 20px;
    left: 26px;
    border-left: 1px solid black;
    position: absolute;
  }
  &::after {
    content: '';
    position: absolute;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    display: block;
    margin: 4px;
    width: 16px;
    height: 16px;
  }
  &-prev::before {
    opacity: .2;
  }
  &-fuel::after {
    background-image: url(../../Assets/refuel.svg);
    background-size: 24px 24px;
    background-position: 1.5px center;
    background-repeat: no-repeat;
    border: none;
    border-radius: 0;
    width: 24px;
    height: 24px;
    margin: 0;
  }
  .route-address:last-child &::before {
    border-left: 1px solid black;
    border-image: url(../../Assets/border.svg) 1;
  }
  .route-address:nth-last-child(2) &::before {
    height: 26px;
  }
  .route-cont &.col-auto {
    margin: 8px 0 0 8px;
  }
}
.route-address:first-child, .route-address:last-child {
  .v-circle::after {
    margin: 0;
    width: 24px;
    height: 24px;
  }
}
.route-address.gu-mirror .v-circle::before {
  border: none;
}

.v-dragger {
  background-image: url(../../Assets/handle.svg);
  background-size: 4px 4px;
  cursor: move;
  z-index: 100;
  top: 1px;
  left: 15px;
  width: 12px;
  height: 24px;
  padding: 0;
  margin-right: 9px;
  touch-action: none;
  position: relative;
  > .hover-text {
    visibility: hidden;
    text-align: center;
    position: absolute;
    margin-left: -12px;
    transform: translate(-100%, calc(-50% + 12px));
    z-index: 150;
    padding: 2px;
    width: 150px;
    border-radius: 2px;
    user-select: none;
    transition: all .2s;
    background-color: transparentize(darken($light-gray, 80), .2);
    color: transparentize(white, .1);
    opacity: 0;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent transparentize(darken($light-gray, 80), .2);
    }
  }
  &:hover > .hover-text {
    visibility: visible;
    opacity: 1;
  }
}

.gu-transit, .gu-mirror {
  > .v-dragger > .hover-text {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

.fade-anim.gu-mirror {
  animation: none;
}

.route-address {
  input {
    margin-left: auto;
  }
}

.remove-link {
  font-weight: bold;
  color: $danger;
  @include colourShape($danger, "M0 4H8");
  &:hover, &:active, &:not(.disabled):not(:disabled):active {
    color: desaturate(darken($danger, 10%), 20%);
  }
}
.route-cont {
  &:not(.fade-anim):not(.route-placeholder) {
    animation: fade-anim-kf $anim-len ease;
  }
  position: relative;
  margin-bottom: 24px;
  .align-right {
    padding: 8px 6px 0 0;
  }
  outline: 0 solid $danger;
  transition: outline $anim-len ease-in;
  .row.route-add {
    margin: 16px -15px 12px -9.5px;
  }
  .custom-switch {
    .custom-control-label {
      font-weight: normal;
    }
  }
}
.modal-map-link {
  font-weight: bold;
  color: $link;
  .icon .ico {
    margin-top: 2px;
    margin-right: 5px;
    fill: $link;
  }
}
.route-remove {
  position: absolute;
  top: 10px;
  left: -55px;
  border-radius: 50%;
  padding: 10px;
  background-color: $danger;
  z-index: 1500;
  svg {
    fill: white;
  }
  &.hidden {
    opacity: 0;
    z-index: 0;
  }
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: unset;
    left: 10px;
    bottom: 10px;
  }
}
h4 ~ .align-right {
  margin-bottom: 8px;
}

.route-invalid {
  outline: 4px solid $danger;
}

.step-cont {
  display: flex;
  flex-direction: column;
  &.detailed .route-address:not(:last-of-type) .v-circle::before {
    height: 48px;
  }
  .details {
    //display: inline-flex;
    padding-left: 160.5px;
    padding-right: 4px;
    margin-bottom: -8px;
    height: 21px;
    width: 100%;
    display: flex;
    &.details-in {
      animation: Details-expand-in 2*$anim-len ease-out;
    }
    &.details-out {
      animation: Details-expand-in 2*$anim-len reverse ease-in;
    }
    > span {
      flex: 1 1;
    }
  }
}

.leaflet-dragging .leaflet-grab {
  cursor: move;
}
.leaflet-grab {
  cursor: default;
}
.map-modal {
  max-width: 100vw;
  overflow: hidden;
  $map-shadow: 0 1px 5px rgba(black, .65);
  height: 100%;

  .modal-content {
    //min-height: calc(100vh - 1rem);
    //noinspection CssInvalidPropertyValue (should be valid? and it is?)
    height: -webkit-fill-available;
  }
  .leaflet-container {
    top: 0;
    bottom: 0;
    position: absolute !important;
    width: 100%;
    border-radius: .25em;
  }

  .leaflet-control-zoom {
    display: none;
  }


  .map-drawer {
    position: absolute;
    z-index: 1100;
    bottom: 10px;
    right: 10px;
    left: 10px;
    > .v-card {
      border-radius: 4px;
      margin-bottom: 0;
      box-shadow: $map-shadow;
      max-width: 380px;
    }

    .save-row {
      margin: 8px 0 0;
      .btn {
        margin: 0;
        width: 100%;
      }
    }
  }

  .close-btn {
    top: 12px;
    right: 12px;
    position: absolute;
    z-index: 1100;
    background-color: white;
    box-shadow: $map-shadow;
    border-radius: 4px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding-left: 7px;
    padding-top: 2px;
    svg {
      width: 16px;
      height: 16px;
      margin: 0;
      path {
        stroke-width: 2.5;
      }
    }
  }

  .map-popup {
    z-index: 1150;
    position: absolute;
    background: #fff;
    flex-direction: column;
    padding: 4px 0;
    border-radius: 4px;
    box-shadow: $map-shadow;
    display: flex;

    > div {
      padding: 4px 8px;
      user-select: none;
      cursor: pointer;
      &:hover {
        background-color: rgba(black, .1);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 90vw;
    max-height: 90vh;

    .close-btn {
      top: 10px;
      right: 10px;
      height: 26px;
      width: 26px;
      padding-top: 0;
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .map-drawer {
      bottom: unset;
      right: unset;
      top: 10px;
    }

    .leaflet-control-zoom {
      display: block;
    }
  }
}

@keyframes Details-expand-in {
  from {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
  }
  to {
    margin-bottom: -8px;
    height: 21px;
    opacity: 1;
  }
}