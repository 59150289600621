@import "../Views/Colors";

.cookie-banner {
  background-color: white;
  box-shadow: $light-shadow-inv;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1500;
  > div {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    position: relative;
  }
  span {
    display: inline-block;
    width: 60%;
  }
}

.cb-btn {
  display: inline-block;
  position: absolute;
  right: 0;
  height: 100%;
  .btn {
    width: 200px;
    height: 100%;
    margin: auto;
  }
}

@include media-breakpoint-down(sm) {
  .cookie-banner > div > span {
    width: 100%;
    padding: 0 12px;
  }
  .cb-btn {
    position: relative;
    display: block;
    margin: 4px 12px;
    .btn {
      width: 100%;
    }
  }
}