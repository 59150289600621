@import "../../Views/Colors";

.edit-name {
  display: inline-block;
  position: relative;

  .__edit-span {
    user-select: none;
    cursor: text;
  }

  input, .__edit-span {
    background-color: transparent;
    border: none;
    font-weight: 500;
    line-height: 1;
    width: auto;
    padding: 0 0 0 22px;

    &::placeholder {
      transition: all $anim-len ease;
      color: $soft-black;
    }

    &:focus::placeholder {
      color: $dark-gray;
    }

    &:focus {
      outline: none;
      text-decoration: underline;
    }

    &:focus, &:hover {
      ~ .icon .ico {
        fill: $soft-black;
      }
    }
  }

  .icon {
    top: -4px;
    position: absolute;
    left: -2px;
    width: 16px;
    height: 16px;
    &:hover .ico {
      fill: $soft-black;
    }
    .ico {
      transition: all $anim-len ease;
      fill: $dark-gray;
    }
  }
}