@import "../Views/Colors";

.ico {
  margin: 4px;
  width: 1em;
  height: 1em;
  &.big {
    width: 1.5em;
    height: 1.5em; }
  vertical-align: middle;
  display: inline-block;
  fill: black;
  transition: fill 200ms; }
a .ico:hover {
  fill: lighten(black, 30); }
.hover-cont {
  .ico {
    z-index: 1000; }
  .hover-body {
    text-align: center;
    position: absolute;
    margin-left: -12px;
    transform: translate(-50%, -100%);
    z-index: 950;
    padding: 2px;
    border-radius: 2px;
    user-select: none;
    transition: all 200ms;
    color: transparent;
    background-color: transparent; }

  *:hover ~ .hover-body {
    background-color: transparentize(darken($light-gray, 80), .2);
    color: transparentize(white, .1); } }
