@import "../Views/Colors";

.navbar {
  box-shadow: $light-shadow;
  z-index: 900;
  &:not(.init) {
    transition: margin-top 350ms ease;
  }

  &.hidden {
    .navbar-brand {
      display: none;
    }
    margin-top: -56px;
    box-shadow: none;
    padding-bottom: 0;
  }
}

.navbar-light {
  .navbar-toggler {
    border: none;
    color: black;
    padding-right: 0;
    &::before {
      top: 2px;
      position: relative;
      content: 'MENU ';
    }
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  img {
    @include media-breakpoint-down(sm) {
      display: none;
    }
    margin-left: 12px;
    border-radius: 100%;
    width: 3em;
    height: 3em;
  }
}

.context-menu {
  z-index: 1000;
  position: absolute;
  top: 48px;
  right: 16px;
  background-color: darken($light-gray, 5);
  padding: 8px;
  display: none;
  &.open {
    display: block;
  }
}

.languages {
  div {
    cursor: pointer;
  }
  .curr {
    font-weight: bold;
  }
}