@import '../../Views/Colors';

.cal-body {
  background-color: white;
  box-shadow: $light-shadow-double;
  width: 416px;
  padding: 8px;
  .header {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }

  .cal-row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    &:not(.names) > span:not(.empty) {
      transition: $anim-len/2 ease;
      transition-property: background-color, border;
      border: 1px solid $border;
      cursor: pointer;
      &:hover {
        background-color: darken(white, 5);
      }
      &.today {
        border: 1px solid $primary;
        background-color: darken(white, 7.5);
      }
      &.red {
        background-color: $danger;
        color: $near-white;
      }
      &.green {
        background-color: $success;
        &.refuel {
          background-image: linear-gradient(225deg, $refuel, $refuel 10px, transparent 10px, transparent);
        }
      }
      &.gray {
        color: lighten($soft-black, 60);
        &.red {
          background-color: lighten($danger, 25);
        }
        &.green {
          background-color: lighten($success, 25);
        }
        &:last-of-type {
          color: lighten($danger, 25);
        }
      }
      &:last-of-type:not(.red):not(.green):not(.gray) {
        color: $danger;
      }
    }
    > span {
      &:first-child {margin-left: 4px;}
      &:last-child {margin-right: 4px;}
      margin: 4px 2px;
      font-size: 20px;
      width: 48px;
      user-select: none;
    }
    &:not(.names) > span {
      height: 48px;
      line-height: 48px;
    }
  }
}

@media screen and (max-width: 450px) {
  .cal-body {
    &.v-card {
      margin: 12px 12px 48px;
    }
    .cal-row {
      > span {
        font-size: calc(100vw / 22.5);
      }
      &:not(.names) > span {
        height: 2em;
        width: 2em;
        line-height: calc(2em - 2px);
      }
    }
  }
}

@keyframes fade-in-left {
  from {opacity: 0; transform: translateX(20px)}
  to   {opacity: 1; transform: translateX(0)}
}
.fade-in-left {animation: fade-in-left $anim-len ease;}

@keyframes fade-in-right {
  from {opacity: 0; transform: translateX(-20px)}
  to   {opacity: 1; transform: translateX(0)}
}
.fade-in-right {animation: fade-in-right $anim-len ease;}