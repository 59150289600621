@import "../Views/Colors";

.popup-alert {
  &__container {
    position: fixed;
    z-index: 1500;
    margin-top: 0;
    top: 0;
    right: 0;
    transition: top 350ms ease;
    max-width: 500px;

    &--lower {
      top: 64px;
    }
  }

  border: 3px solid black;
  box-shadow: 0 1px 6px 1px #0008;
  background: #0002;
  padding: 8px;
  margin: 8px;
  border-radius: 6px;

  & > .icon {
    float: right;
    margin-top: -8px;
    margin-right: -8px;
    cursor: pointer;

    .ico {
      height: 12px;
    }
  }

  &--entering {
    animation: popup-enter $anim-len ease forwards;
  }
  &--fading {
    animation: popup-exit $anim-len ease forwards;
  }

  @mixin color-alert($col) {
    border: 3px solid $col;
    box-shadow: 0 2px 8px 4px transparentize($col, .5);
    background: transparentize($col, .7);

    & .ico > path {
      //right: 0;
      stroke: $col;
    }
  }

  &--success { @include color-alert($success); }
  &--info { @include color-alert(#17A2B8); }
  &--warning { @include color-alert(#FFC107); }
  &--error { @include color-alert($danger); }
}

@keyframes popup-enter {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes popup-exit {
  from {
    opacity: 1;
    margin-top: 8px;
  }
  to {
    opacity: 0;
    margin-top: calc(-100% + 5px);
  }
}