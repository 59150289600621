@import "../../Views/Colors";

.dropdown-input {
  position: relative;
  height: calc(1.5em + .75rem + 2px);
  z-index: 100;
  transition: z-index 0s .25s;

  > * {
    z-index: 104;
  }

  input.head {
    position: absolute;
    z-index: 103;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 2rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: border .25s;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $dark-gray;
    }
    &::selection {
      background-color: lighten($primary, 25);
    }
  }

  .body-wrap {
    padding-top: calc(1.5em + .75rem);
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 101;
    background-color: white;
    border: 1px solid $gray;
    border-radius: .25rem;
    transition: box-shadow .25s, border .25s;
  }

  &.upwards {
    .body-wrap {
      top: unset;
      bottom: 0;
      padding-top: 0;
      padding-bottom: calc(1.5em + .75rem + 1px);
    }
  }

  &.open {
    @keyframes z-index-kf {
      0%   { z-index: 102; }
      100% { z-index: 102; }
    }
    animation: z-index-kf .25s linear;
    z-index: 101;
    transition-delay: 0s;
    &:not(.upwards) {
      .head {
        border-bottom-color: $gray;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &.upwards {
      .head {
        border-top-color: $gray;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .head:focus ~ .body-wrap, &.open .body-wrap {
    border-color: $primary;
    box-shadow: 0 0 0 4px transparentize($primary, .75);
  }
}