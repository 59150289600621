@import "../Views/Colors";

.v-card {
  margin: 0 auto 48px;
  padding: 12px;
  background-color: white;
  box-shadow: $light-shadow;
  .header {
    transition: padding-bottom $anim-len;
    font-weight: bold;
    font-size: 18px;
    .collapser {
      cursor: pointer;
      float: right;
      color: $dark-gray;
      font-size: 16px;
      .ico {
        width: 12px;
        fill: $dark-gray;
        margin-top: -1px;
        transition: all $anim-len ease;
        transform: rotate(180deg);
      }
    }
    &.collapsed {
      transition-delay: $anim-len;
      padding-bottom: 0;
      .ico {
        transform: rotate(0deg);
      }
    }
  }
  .row {
    margin-top: 8px;
    > :last-child {
      margin-right: 5px;
    }
  }
}

.c-body {
  .row {
    .col, .col-auto {
      margin: auto 0;
    }
  }
}