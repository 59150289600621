@import "../Colors";

.new-v-link {
  font-weight: bold;
  color: $link;
  @include colourShape($link, "M0 4H8M4 0V8");
}

.center {
  text-align: center;
  margin-top: 24px;
  @include media-breakpoint-only(xs) {
    &.empty{
      margin-top: 128px;
    }
  }
}

.main-container > div >  header {
  margin-top: 48px;
  margin-left: 12px;
}

.body {
  margin: 24px 12px;
}

p {
  margin-bottom: 12px;
}

.finish-col {
  width: 1%;
  text-align: center;
}

table {
  td, th {
    vertical-align: middle !important;
  }
}

.card {
  margin: 16px 0;
  border: none;
  border-radius: 0;
  box-shadow: $light-shadow;
}

.modal .btns {
  width: 100%;
  margin-right: 0;
}
.btns {
  display: flex;
  > .btn {
    flex-grow: 100;
    width: auto;
    margin-left: 8px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  > .col-sm, > .col {
    .btn {
      width: 100%;
    }
    padding-left: 16px;
    padding-right: 16px;

    @include media-breakpoint-up(sm) {
      &:first-child {
        padding-right: 8px;
      }
      &:last-child {
        padding-left: 8px;
      }
    }
  }
}

.routes {
  margin: 0 auto auto;
  h4, .align-right {
    padding: 0 12px;
  }
  .align-right {
    width: 100%;
    text-align: right;
  }
  &-end {
    width: 480px;
    flex: none;
    .row {
      margin-top: 8px;
      .btn {
        margin-top: 8px;
      }
      .col {
        margin: auto 0;
      }
    }
  }
}

.spacer {
  padding-bottom: 48px;
}

.common {
  width: 720px;
  margin: 0 auto;
  label {
    margin: 12px 0 6px 0;
  }
}

.v-card {
  @include media-breakpoint-up(sm) {
    .divider {
      margin: 0 !important;
      padding: 0;
      border-left: 1px solid $gray;
    }
  }
  h5 {
    font-weight: bold;
    display: inline-block;
    margin: 12px 0 6px 0;
    line-height: 1.35;
    font-size: 1rem;
  }
}

.form-group.no-add {
  margin-bottom: 1.2em;
}

.consumption {
  > :nth-child(2) {
    padding-right: 2px;
  }
  > :nth-child(3) {
    vertical-align: middle;
    line-height: 32px;
    padding-left: 2px;
  }
}

@include media-breakpoint-up(xl) {
  .cal-card {
    margin: 64px 24px 24px;
    position: sticky;
    top: 24px;
    align-self: flex-start;
  }
}

.cal-card {
  box-shadow: $light-shadow-double;
  max-width: calc(100vw - 47px);
  width: 416px;
  margin-bottom: 2rem;
  .cal-body {
    box-shadow: none;
    max-width: 100%;
  }
  @include media-breakpoint-between(md, lg) {
    width: auto;
  }
}


.cal-extension {
  &.v-card {
    box-shadow: none;
    margin: 0;
    @include media-breakpoint-between(md, lg) {
      margin: 0 0 0 -1px;
      .collapser {
        display: none;
      }
    }
  }
  .custom-switch {
    .custom-control-label {
      font-weight: normal;
    }
  }
}

.route-container {
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: center;
}
.route-cont {
  width: 480px;
  &.route-placeholder {
    background-color: transparent;
    transition: background-color $anim-len;
    outline: 2px dashed $primary;
    color: $primary;
    text-align: center;
    margin-top: 27px;
    padding: 2em;
    cursor: pointer;
    &:hover {
      background-color: rgba(black, .05);
      span {
        text-decoration: underline;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .common {
    width: auto;
  }
}

@include media-breakpoint-down(xs) {
  .routes {
    .route-container > div {
      width: auto;
    }
    width: auto;
  }
  .route-cont {
    width: auto;
  }
}
