@import "../Views/Colors";

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loading {
  margin: .25rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
  > svg {
    $length: 500ms;
    height: 48px;
    @for $i from 1 through 8 {
      :nth-child(#{$i}) {
        animation: fade ease-in infinite #{$length} #{($length / -8) * $i};
      }
    }
  }
}