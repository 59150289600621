@import "../../Views/Colors";

.date-input {
  width: 250px;

  .head {
    cursor: default;
    user-select: none;
  }
  .head:focus, .head:hover, &.open .head {
    & ~ .icon .ico {
      fill: $soft-black;
    }
  }
  > .icon {
    position: absolute;
    pointer-events: none;
    top: 3px;
    right: 3px;
    .ico {
      transition: all $anim-len ease;
      fill: $dark-gray;
      width: 24px;
      height: 24px;
    }
  }

  .body {
    width: 100%;
    overflow: hidden;
    background-color: white;
    box-shadow: none;
    padding: 0;
    margin: 0;
    border-radius: .25rem;
    .header {
      font-size: 16px;
    }
    .names > span {
      font-size: 10px !important;
    }
    .cal-row > span {
      height: auto !important;
      line-height: normal !important;
      margin: 2px;
      font-size: 16px;
    }
  }

  &.open .head {
    color: transparent;
    text-shadow: 0 0 0 $soft-black;
  }

}

.modal-cont {
  .cal-body {
    width: auto;
    box-shadow: none;
    margin: 0;
    border-radius: .25rem;
  }
}