@import "../../Views/Colors";

.select {
  > .icon {
    position: absolute;
    pointer-events: none;
    top: 6px;
    .ico {
      transition: all .25s ease;
    }
    &:not(.head-search):not(.head-arrow) {
      position: absolute;
      top: 3px;
      right: 3px;
      cursor: pointer;
      .ico {
        transition: all $anim-len ease;
        width: 24px;
        height: 24px;
      }
    }
  }
  .icon.head-search {
    left: -28px;
    .ico {
      opacity: 0;
      fill: $soft-black;
    }
  }
  .icon.head-arrow {
    right: 8px;
    .ico {
      fill: $dark-gray;
      width: 14px;
    }
  }


  .body {
    margin: 0;
    max-height: 300px;
    overflow: auto;
    option {
      padding: .5em .75em;
      user-select: none;
      display: inline-block;
      min-width: 100%;
      &.hover {
        background-color: $primary;
        color: white;
      }
      &[disabled].hover {
        background-color: $gray;
        color: black;
      }
    }
  }

  &.open {
    .head-search .ico {
      opacity: 1;
    }
    .head-arrow .ico {
      transform: rotate(.5turn);
    }
  }

  &:not(.open) {
    .head {
      color: transparent;
      text-shadow: 0 0 0 $soft-black;
      &::placeholder {
        color: transparent;
        text-shadow: 0 0 0 $dark-gray;
      }
    }
  }

  &.select-loading {
    position: relative;
    .loading {
      position: absolute;
      top: 6px;
      right: 8px;
      width: auto;
    }
    svg {
      height: 16px;
    }
  }

  &.invalid {
    .body-wrap {
      border-color: $danger;
      box-shadow: 0 0 0 4px transparentize($danger, .75);
    }
    .icon .ico {
      fill: $danger;
    }
  }
}