@import "../Colors";

.login-cont {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 11em);
  @media screen and (max-height: 670px) {
    height: 490px;
  }

  .spacer {
    flex-grow: 1;
  }

  header, footer {
    text-align: center;
  }

  form {
    background-color: white;
    width: 336px;
    max-width: 100%;
    box-shadow: $light-shadow-double;
    margin: 32px auto 16px;
    border-radius: 8px;
    padding: 24px;
    .btn {
      width: 100%;
    }
    .alert {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }
}

.account-cont {
  .card {
    //width: 225px;
    min-width: 200px;
    height: 160px;
    box-shadow: $light-shadow-double;
    border: none;
    border-radius: 0;
    margin: 12px 0;
  }
  .card-body {
    cursor: pointer;
    font-size: 28px;
    margin: auto;
    vertical-align: middle;
  }
  .card-footer {
    background-color: inherit;
    border: none;
    padding: 8px 12px;
    text-align: right;
  }
  .custom-radio {
    padding-left: unset;
    padding-right: 1.5em;
  }
  .custom-control-label {
    &:after, &:before {
      left: unset;
      right: -1.5em;
    }
  }
  .row {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  h3 {
    margin-left: 15px;
  }
  .add-new {
    border: 2px solid $danger;
    .card-body {
      font-size: 22px;
      color: $danger;
      flex-direction: column;
      text-align: center;
      &:before {
        content: "+";
        color: $danger;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    .row > *, .card {
      width: 100%;
    }
  }
}
.modal {
  .loc.form-control {
    width: 100%;
  }
}