@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

$primary: #4A90E2;
$secondary: #999999;
$danger: #F44336;
$success: #7ED321;

$link: $primary;
$refuel: #db3585;

$near-white: #FAFAFA;
$light-gray: #F8F9FA;
$gray: #CCCCCC;
$dark-gray: #9B9B9B;
$soft-black: #212121; // gray 900

$border: #F2F2F2;

$drop-shadow: 0 4px 8px 0 rgba(#000,0.12);
$light-shadow-color: rgba(#333, 0.15);
$light-shadow: 0 4px 8px 0 $light-shadow-color;
$light-shadow-double: 0 8px 16px 0 $light-shadow-color;
$light-shadow-inv: 0 -4px 8px 0 $light-shadow-color;

@function fixHex($color) {
  @return "%23#{str-slice(inspect($color), 2)}";
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$anim-len: .15s;

@mixin colourShape($col, $shape) {
  &::before {
    content: "";
    width: 1em;
    height: 1em;
    margin-right: 4px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><path stroke='#{fixHex($col)}' d='#{$shape}'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: inline-block;
    vertical-align: middle;
  }
}
