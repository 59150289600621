@import "../../Views/Colors";

.time-picker {
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: $drop-shadow;

  > .header {
    flex: 0 0;
    background-color: $primary;
    color: rgba(white, .5);
    padding: .3rem;
    text-align: center;
    font-size: 4rem;
    .active {
      color: rgba(white, .9);
    }
  }

  .cont {
    position: relative;
    transform: scale(.95);
    flex: 0 0 300px;
    touch-action: none;

    > * {
      position: absolute;
    }

    > .hand {
      border-radius: 50%;
      background-color: rgba(gray, .2);
      > g {
        $color: black;
        opacity: .6;
        circle {
          fill: $color;
        }
        line {
          stroke: $color;
        }
      }
    }

    &.suspended {
      cursor: move;
      > .face span {
        cursor: move;
        &:hover {
          background-color: transparent;
        }
      }
    }

    > .face {
      position: absolute;
      text-align: center;
      line-height: 40px;
      width: 300px;
      height: 300px;

      .inner {
        line-height: 30px;
        span {
          color: rgba(black, .4);
          font-size: 1em;
          width: 30px;
          height: 30px;
        }
      }
      span {
        position: absolute;
        font-size: 1.4rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: background-color $anim-len ease;
        user-select: none;
        cursor: pointer;
        &.active {
          color: white;
        }
        &:hover {
          background-color: rgba(black, .4);
        }
      }
    }
  }

  > .footer {
    flex: 0 0;
    display: flex;
    padding: 0 .5rem .5rem;
    > .t-btn {
      flex: 1;
      text-align: center;
      text-transform: uppercase;
      font-size: .9rem;
      border-radius: .3rem;
      background-color: transparent;
      transition: background-color .2s ease;
      padding: .5rem;
      color: $link;
      font-weight: bold;

      &:not(.empty) {
        cursor: pointer;
        &:hover {
          background-color: rgba(gray, .2);
        }
      }
    }
  }

/*  &.sm {
    width: 200px;
    .cont {
      flex-basis: 200px;
      transform: scale(.633);
      transform-origin: 10% 10%;
    }
  }*/
}