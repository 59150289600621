@import "../../Views/Colors";

.loc {
  .head:focus, .head:hover, &.open .head {
    & ~ .icon .ico {
      fill: $soft-black;
    }
  }
  > .icon {
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    .ico {
      transition: all $anim-len ease;
      fill: $dark-gray;
      width: 24px;
      height: 24px;
    }
  }
}