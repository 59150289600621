@import "../../Views/Colors";
@import "DropdownInput";

.modal-input {
  @extend .dropdown-input;

  &.open {
    &:not(.upwards) {
      .head {
        border-color: $primary;
        border-radius: .25rem;
      }
    }
  }
}