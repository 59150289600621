@import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import '../../node_modules/react-dragula/dist/dragula.min.css';
@import "../Components/Icon";

@import "Colors";


h2 {
  font-weight: normal;
  height: 39px;
}


:not(.btn-group) > .btn {
  border-radius: 4px;
  font-weight: normal;
  margin: 0;
  padding: .375em 1em;
  box-shadow: $drop-shadow;
  @mixin colorButton($col) {
    background-color: $col;
    border-color: $col;
    &:not(.disabled):not(:disabled) {
      &:hover, &:active {
        background-color: desaturate(darken($col, 10%), 20%);
        border-color: desaturate(darken($col, 10%), 20%);
      }
    }
    &:active:focus {
      box-shadow: 0 0 0 0.2em transparentize(lighten($col, 10%), 0.5);
    }
  }
  &.btn-primary   { @include colorButton($primary  ) }
  &.btn-secondary { @include colorButton($secondary) }
  &.btn-danger    { @include colorButton($danger   ) }
  &.btn-success   { @include colorButton($success  ) }
}

.custom-switch > .custom-control-label {
  $offset: 2px;
  &:before {
    top: $offset;
  }
  &:after {
    top: $offset + 2px;
  }
}

body {
  background-color: $near-white;
  color: $soft-black;
}

.main-container {
  padding-top: 1em;
  padding-bottom: 4em;
  line-height: 1.35;
  word-wrap: break-word;
  white-space: pre-wrap;
  transition: min-height 350ms ease;
}

.navbar:not(.hidden) ~ div .main-container {
  min-height: calc(100% - 64px);
  transition-delay: -.35s;
}

.form-control {
  border-color: $gray;
  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 4px transparentize($primary, 0.75);
  }
  &:disabled {
    border-color: lighten($gray, 10);
    background-color: $near-white;
  }
}

.alert-dismissible .close {
  padding: 0.5rem 1rem;
}

/*.fade-obj {
  .fade-container {
    position: absolute;
  }

  .fade-enter {
    opacity: 0;
    z-index: 1;
    &.fade-enter-active {
      opacity: 1;
      transition: opacity 100ms ease-in;
    }
  }

  .fade-exit {
    opacity: 1;
    z-index: 0;
    &.fade-exit-active {
      opacity: 0;
      transition: opacity 100ms ease-in;
    }
  }

  > * {
    display: flex;
    justify-content: center;
  }
}*/

.fade-anim {
  @keyframes fade-anim-kf {
    0%   { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  animation: fade-anim-kf 2*$anim-len ease;
}

.small-fade-anim {
  @keyframes small-fade-anim-kf {
    0%   { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  animation: fade-anim-kf $anim-len ease;
}

.slide-anim-in {
  @keyframes slide-anim-in-kf {
    from { transform: translateY(100%); }
    to   { transform: translateY(0);    }
  }
  animation: slide-anim-in-kf 4*$anim-len ease;
}
.slide-anim-out {
  @keyframes slide-anim-out-kf {
    from { transform: translateY(0);    }
    to   { transform: translateY(100%); }
  }
  animation: slide-anim-out-kf 4*$anim-len ease;
  transform: translateY(100%);
}

.copyright {
  text-align: center;
}

.feedback {
  &--good {
    color: $success;
  }
  &--bad {
    color: $danger;
  }
/*  &__field {
    &--good {
      border-color: $success;
    }
    &--bad {
      border-color: $danger;
    }
  }*/
}